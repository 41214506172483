import React from 'react';
import ProviderPage from '../../components/ProviderPage';

const providerID = 'studentenarts-utrecht';
const providerName = 'Studentenarts Utrecht';
const providerPhoneNumber = '030 - 760 13 00';
const providerWebsite = 'http://studentenarts.nl/';
const providerImage =
  'https://storage.googleapis.com/planl-zorg.appspot.com/images/studentenarts-utrecht/uithof.jpg';
const providerRemarks =
  'Je kunt alleen online een afspraak maken als je al staat ingeschreven bij de praktijk.';

const providerLocations = {
  ut: {
    name: 'Huisartsenpraktijk Janskerkhof',
    address: 'Nobelstraat 2a\n3512EN Utrecht',
    coordinates: {
      lat: 52.093259,
      lng: 5.123505
    }
  },
  saxion: {
    name: 'Huisartsenpraktijk de Uithof',
    address: 'Leuvenplein 10\n3584LA Utrecht',
    coordinates: {
      lat: 52.085011,
      lng: 5.173483
    }
  }
};

const providerEmployees = {};

const providerDescription = `De Studentenarts Utrecht is de studentenhuisartsenpraktijk in Utrecht. De studentenarts in Utrecht heeft vestigingen op het centrum van Utrecht (janskerkhof – universiteitskwartier)  en op de Uithof – Utrecht Science park. De studentenarts is er speciaal voor alle studenten en medewerkers van de Universiteit Utrecht en de Hogeschool Utrecht. Maar ook andere patiënten en passanten zijn natuurlijk van harte welkom.

Je kunt 24 uur per dag nu heel eenvoudig een afspraak met de studentenarts in Utrecht maken via Planl. Klik simpelweg op de bovenstaande knop. Via Planl kan je zowel een afspraak maken op de praktijklocatie Janskerkhof als op de Uithof.`;

const providerMetaDescription = 'Afspraak maken bij Studentenarts Utrecht';
const providerMetaKeywords =
  'Afspraak maken,Studentenarts Utrecht,Studentenarts,Utrecht,Janskerkhof,Huisarts Utrecht,Huisarts studenten,studenten,Huisarts,De Uithof';

export default function StudentenArtsUtrecht() {
  return (
    <ProviderPage
      providerID={providerID}
      providerName={providerName}
      providerPhoneNumber={providerPhoneNumber}
      providerWebsite={providerWebsite}
      providerImage={providerImage}
      providerRemarks={providerRemarks}
      providerLocations={providerLocations}
      providerEmployees={providerEmployees}
      providerDescription={providerDescription}
      providerMetaDescription={providerMetaDescription}
      providerMetaKeywords={providerMetaKeywords}
    />
  );
}
